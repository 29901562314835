import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditDialog from "./dialogs/createEditDialog";
import useFamilyGroup from "./useFamilyGroup";

const FamilyGroup = ({ code, name, handleGoBack }) => {

  const {
    data,
    typeList,
    statusList,
    isLoading,
    state,
    handlers
  } = useFamilyGroup(code);

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => handleGoBack()}
      type={"goBack"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={`Grupo Familiar - ${name}`}
      gridTitle={`Grupo Familiar - ${name}`}
      columnDefPage={paths.familyGroup}
      rowData={data}
      menuItems={[goBackButton, createButton, modifyButton, deleteButton]}
      hasExpand
      hasHelp
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDialog}
          typeList={typeList}
          statusList={statusList}
          code={code}
        />
      }
    </CommonPage>
  )
}

export default FamilyGroup;