import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {      
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = ["code", "to", "value"];
    
    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })      
    }, {});
  }

  const [ formData, setFormData ] = useState(createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z]+[A-Z0-9]*/;

    let schema = yup.object().shape({
      code: yup.string().matches(codeRegex).required(),
      to: yup.string().required(),      
      value: yup.string().required(),      
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if(await dataIsValid()){
      let dataToSend = { ...formData };

      if(!isCreate){
        dataToSend = { ...dataToSend, internalCode: data.internalCode };
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    }else{
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if(fieldName === "code"){
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    if(fieldName === "to" || fieldName === "value"){
      if(value.indexOf('.') > 0){
        return value.substring(0, value.indexOf('.') + 3);
      }
      
      return value;
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
