import { useState } from "react";
import * as yup from 'yup';
import { createDateFromDDMMYYYY, formatDateYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const getTwoStringsCombinatio = (stringOne, stringTwo) => {
    if (!stringOne && !stringTwo) return '';
    if (stringOne && !stringTwo) return stringOne;
    if (!stringOne && stringTwo) return stringTwo;
    return `${stringOne} ${stringTwo}`;
  }

  const getFullNameString = (fatherSurname, motherSurname, firstName, lastName) => {
    if (!fatherSurname && !motherSurname && !firstName && !lastName) return "";

    const fullNameFirstPart = getTwoStringsCombinatio(fatherSurname, motherSurname);
    const fullNameSecondPart = getTwoStringsCombinatio(firstName, lastName);

    if (fullNameFirstPart && fullNameSecondPart) return `${fullNameFirstPart}, ${fullNameSecondPart}`;
    if (!fullNameFirstPart) return fullNameSecondPart;
    return fullNameFirstPart;
  }

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName.includes('Fecha')) {
        return isCreate ? new Date() : (data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null);
      }

      if (fieldName === 'Año del certificado escolar') {
        return isCreate ? new Date() : (data[fieldName] ? new Date(data[fieldName], 0, 1) : null);
      }

      if (['Asignación familiar', 'Retención judicial', 'Incapacidad'].includes(fieldName)) {
        return isCreate ? 'N' : data[fieldName];
      }

      if (fieldName === 'Apellidos y nombres') {
        return isCreate ? '' : getFullNameString(data['Apellido paterno'], data['Apellido materno'], data['Primer nombre'], data['Segundo nombre']);
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'Código de familiar',
      'Apellido paterno',
      'Apellido materno',
      'Primer nombre',
      'Segundo nombre',
      'Apellidos y nombres',
      'Tipo de familiar',
      'Sexo',
      'Fecha de ingreso',
      'Fecha de nacimiento',
      'Fecha de casamiento',
      'Asignación familiar',
      'Fecha de vencimiento de la asignación',
      'Retención judicial',
      'Incapacidad',
      'Escolaridad',
      'Año del certificado escolar',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Código de familiar': yup.string().required(),
      'Apellido paterno': yup.string().required(),
      'Apellido materno': yup.string(),
      'Primer nombre': yup.string().required(),
      'Segundo nombre': yup.string(),
      'Apellidos y nombres': yup.string().required(),
      'Tipo de familiar': yup.string().required(),
      'Sexo': yup.string().required(),
      'Fecha de ingreso': yup.date().required(),
      'Fecha de nacimiento': yup.date().nullable(true)
        .when('Tipo de familiar', {
          is: (value) => value === 'HIJ',
          then: yup.date().required()
        }),
      'Fecha de casamiento': yup.date().nullable(true),
      'Asignación familiar': yup.string().required(),
      'Fecha de vencimiento de la asignación': yup.date().nullable(true),
      'Retención judicial': yup.string().required(),
      'Incapacidad': yup.string()
        .when('Tipo de familiar', {
          is: (value) => value === 'HIJ',
          then: yup.string().required()
        }),
      'Escolaridad': yup.string(),
      'Año del certificado escolar': yup.date().nullable(true)
        .when('Escolaridad', {
          is: (value) => Boolean(value),
          then: yup.date().required()
        }),
    });
    
    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        'Fecha de ingreso': formData['Fecha de ingreso'] ? formatDateYYYYMMDD(formData['Fecha de ingreso']) : null,
        'Fecha de nacimiento': formData['Fecha de nacimiento'] ? formatDateYYYYMMDD(formData['Fecha de nacimiento']) : null,
        'Fecha de casamiento': formData['Fecha de casamiento'] ? formatDateYYYYMMDD(formData['Fecha de casamiento']) : null,
        'Fecha de vencimiento de la asignación': formData['Fecha de vencimiento de la asignación'] ? formatDateYYYYMMDD(formData['Fecha de vencimiento de la asignación']) : null,
        'Año del certificado escolar': formData['Año del certificado escolar'] ? formatDateYYYY(formData['Año del certificado escolar']) : null,
      };

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (['Apellido paterno', 'Apellido materno', 'Primer nombre', 'Segundo nombre'].includes(fieldName)) {
      let newFatherSurname = formData['Apellido paterno'];
      let newMotherSurname = formData['Apellido materno'];
      let newFirstName = formData['Primer nombre'];
      let newLastName = formData['Segundo nombre'];

      if (fieldName === 'Apellido paterno') newFatherSurname = value;
      if (fieldName === 'Apellido materno') newMotherSurname = value;
      if (fieldName === 'Primer nombre') newFirstName = value;
      if (fieldName === 'Segundo nombre') newLastName = value;

      setFormData({
        ...formData,
        [fieldName]: value,
        'Apellidos y nombres': getFullNameString(newFatherSurname, newMotherSurname, newFirstName, newLastName),
      });
      return;
    }

    if (fieldName === 'Tipo de familiar') {
      setFormData({
        ...formData,
        [fieldName]: value,
        'Fecha de nacimiento': null,
        'Fecha de casamiento': null,
        'Incapacidad': 'N',
        'Escolaridad': '',
      });
      return;
    }

    if (fieldName === 'Asignación familiar') {
      setFormData({
        ...formData,
        [fieldName]: value,
        'Fecha de vencimiento de la asignación': null,
      });
      return;
    }

    if (fieldName === 'Escolaridad') {
      setFormData({
        ...formData,
        [fieldName]: value,
        'Año del certificado escolar': null,
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
