import * as actionTypes from "./actionTypes";
import {
  getFamilyGroupApi,
  createFamilyGroupApi,
  updateFamilyGroupApi,
  deleteFamilyGroupAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { openDialogAction } from "@icarius-common/dialog/actions";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'No puede existir más de un familiar con el mismo código';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getFamilyGroupAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getFamilyGroupApi(`?code=${code}`);

    let data = response.data && response.data.data;
    let familyTypeList = response.data && response.data.familyTypeList;
    let sexList = response.data && response.data.sexList;
    let scholarshipList = response.data && response.data.escList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, familyTypeList, sexList, scholarshipList, },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createFamilyGroupAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createFamilyGroupApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Familiar creado con éxito', severity: "success" }));

    if (response.data.showAlert) {
      dispatch(openDialogAction({
        msg: 'Ha modificado el grupo familiar y el colaborador existe en un proceso de cálculo actual, es necesario reprocesarlo',
        title: 'Atención',
        acceptOnly: true,
      }));
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateFamilyGroupAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateFamilyGroupApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Familiar actualizado con éxito', severity: "success" }));

    if (response.data.showAlert) {
      dispatch(openDialogAction({
        msg: 'Ha modificado el grupo familiar y el colaborador existe en un proceso de cálculo actual, es necesario reprocesarlo',
        title: 'Atención',
        acceptOnly: true,
      }));
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteFamilyGroupAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteFamilyGroupAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro de familiar eliminado con éxito', severity: "success" }));

    if (response.data.showAlert) {
      dispatch(openDialogAction({
        msg: 'Ha modificado el grupo familiar y el colaborador existe en un proceso de cálculo actual, es necesario reprocesarlo',
        title: 'Atención',
        acceptOnly: true,
      }));
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};