import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getFamilySettings,
  createFamilySettings,
  updateFamilySettings,
  deleteFamilySettings,
} from "@icarius-connection/api";

export const getFamilySettingsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FAMILY_SETTINGS });
  try {
    let response = await getFamilySettings();

    dispatch({
      type: actionTypes.GET_FAMILY_SETTINGS_FULFILLED,
      payload: {
        rows: response.data.result,
      }
    });
    
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FAMILY_SETTINGS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

//UPDATES
export const updateFamilySettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_FAMILY_SETTINGS });
  try {
    let response = await updateFamilySettings({ familySettings: data });
    
    dispatch({ type: actionTypes.UPDATE_FAMILY_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("familyUpdatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("familyDuplicatedData"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.UPDATE_FAMILY_SETTINGS_REJECTED, payload: e });    
    return e.response;
  }
};

//CREATE
export const createFamilySettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_FAMILY_SETTINGS });
  try {
    let response = await createFamilySettings({ familySettings: data });
    
    dispatch({ type: actionTypes.CREATE_FAMILY_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("familyCreatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("familyDuplicatedData"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_FAMILY_SETTINGS_REJECTED, payload: e });
    return e.response;
  }
};

//DELETE
export const deleteFamilySettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FAMILY_SETTINGS });
  try {
    let response = await deleteFamilySettings(data);
    
    dispatch({ type: actionTypes.DELETE_FAMILY_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("familyDeletedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "MANAGEMENT_CENTER_IN_USE"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("cantDeleteFamily"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));  
    }
    dispatch({ type: actionTypes.DELETE_FAMILY_SETTINGS_REJECTED, payload: e });    
    return e.response;
  }
};
