import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createFamilyGroupAction, updateFamilyGroupAction, } from "../../actions";
import { getFamilyTypeList, getSexList, getScholarshipList } from "../../selectors";
import { useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import Loader from "@icarius-common/loader";
import { yesNoArray } from "@icarius-utils/constants";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const { open, isLoading, data, code, handleClose } = props;

  const dispatch = useDispatch();
  const familyTypeList = useSelector(getFamilyTypeList);
  const sexList = useSelector(getSexList);
  const scholarshipList = useSelector(getScholarshipList);

  const create = (dataToSend) => {
    dispatch(createFamilyGroupAction({ 'Código de empleado': code, ...dataToSend }))
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
        }
      });
  }

  const modify = (dataToSend) => {
    dispatch(updateFamilyGroupAction({ internalCode: data.internalCode, 'Código de empleado': code, ...dataToSend }))
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
        }
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear registro de familiar" : "Editar registro de familiar"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Código de familiar'}
                onChange={(e) => setFormValue(e.target.value, "Código de familiar")}
                value={formData['Código de familiar']}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Apellido paterno'}
                onChange={(e) => setFormValue(e.target.value, "Apellido paterno")}
                value={formData['Apellido paterno']}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Apellido materno'}
                onChange={(e) => setFormValue(e.target.value, "Apellido materno")}
                value={formData['Apellido materno']}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Primer nombre'}
                onChange={(e) => setFormValue(e.target.value, "Primer nombre")}
                value={formData['Primer nombre']}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Segundo nombre'}
                onChange={(e) => setFormValue(e.target.value, "Segundo nombre")}
                value={formData['Segundo nombre']}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                disabled
                fullWidth
                margin={"none"}
                label={'Apellidos y nombres'}
                value={formData['Apellidos y nombres']}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de familiar"}</InputLabel>
                <Select
                  labelId={`label-Tipo de familiar`}
                  id={`select-Tipo de familiar`}
                  value={formData['Tipo de familiar']}
                  onChange={(e) => setFormValue(e.target.value, "Tipo de familiar")}
                  margin={"none"}
                >
                  {
                    familyTypeList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-sex`}>{"Sexo"}</InputLabel>
                <Select
                  labelId={`label-Sexo`}
                  id={`select-Sexo`}
                  value={formData['Sexo']}
                  onChange={(e) => setFormValue(e.target.value, "Sexo")}
                  margin={"none"}
                >
                  {
                    sexList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha de ingreso'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha de ingreso']}
                  onChange={(e) => setFormValue(e, 'Fecha de ingreso')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {
              formData['Tipo de familiar'] === 'HIJ' &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    required
                    label={'Fecha de nacimiento'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=""
                    format="dd/MM/yyyy"
                    margin="none"
                    value={formData['Fecha de nacimiento']}
                    onChange={(e) => setFormValue(e, 'Fecha de nacimiento')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              formData['Tipo de familiar'] === 'CON' &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    label={'Fecha de casamiento'}
                    clearable
                    clearLabel={getLocalizedString("clear")}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=""
                    format="dd/MM/yyyy"
                    margin="none"
                    value={formData['Fecha de casamiento']}
                    onChange={(e) => setFormValue(e, 'Fecha de casamiento')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Asignación familiar`}>{"Asignación familiar"}</InputLabel>
                <Select
                  labelId={`label-Asignación familiar`}
                  id={`select-Asignación familiar`}
                  value={formData['Asignación familiar']}
                  onChange={(e) => setFormValue(e.target.value, "Asignación familiar")}
                  margin={"none"}
                >
                  {
                    yesNoArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              formData['Asignación familiar'] === 'Y' &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    label={'Fecha de vencimiento de la asignación'}
                    clearable
                    clearLabel={getLocalizedString("clear")}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=""
                    format="dd/MM/yyyy"
                    margin="none"
                    value={formData['Fecha de vencimiento de la asignación']}
                    onChange={(e) => setFormValue(e, 'Fecha de vencimiento de la asignación')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Retención judicial`}>{"Retención judicial"}</InputLabel>
                <Select
                  labelId={`label-Retención judicial`}
                  id={`select-Retención judicial`}
                  value={formData['Retención judicial']}
                  onChange={(e) => setFormValue(e.target.value, "Retención judicial")}
                  margin={"none"}
                >
                  {
                    yesNoArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              formData['Tipo de familiar'] === 'HIJ' &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-Incapacidad`}>{"Incapacidad"}</InputLabel>
                  <Select
                    labelId={`label-Incapacidad`}
                    id={`select-Incapacidad`}
                    value={formData['Incapacidad']}
                    onChange={(e) => setFormValue(e.target.value, "Incapacidad")}
                    margin={"none"}
                  >
                    {
                      yesNoArray.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            {
              formData['Tipo de familiar'] === 'HIJ' &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-Escolaridad`}>{"Escolaridad"}</InputLabel>
                  <Select
                    labelId={`label-Escolaridad`}
                    id={`select-Escolaridad`}
                    value={formData['Escolaridad']}
                    onChange={(e) => setFormValue(e.target.value, "Escolaridad")}
                    margin={"none"}
                  >
                    <MenuItem className={"whiteText"} value={''}>{'Sin valor'}</MenuItem>
                    {
                      scholarshipList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            {
              formData['Escolaridad'] &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    required
                    label={'Año del certificado escolar'}
                    clearable
                    clearLabel={getLocalizedString("clear")}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=""
                    format="yyyy"
                    margin="none"
                    value={formData['Año del certificado escolar']}
                    onChange={(e) => setFormValue(e, 'Año del certificado escolar')}
                    views={["year"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
