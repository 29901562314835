import NAME from "./constants";

export const GET_FAMILY_SETTINGS = NAME + "/GET_FAMILY_SETTINGS";
export const GET_FAMILY_SETTINGS_FULFILLED = NAME + "/GET_FAMILY_SETTINGS_FULFILLED";
export const GET_FAMILY_SETTINGS_REJECTED = NAME + "/GET_FAMILY_SETTINGS_REJECTED";

export const CREATE_FAMILY_SETTINGS = NAME + "/CREATE_FAMILY_SETTINGS";
export const CREATE_FAMILY_SETTINGS_FULFILLED = NAME + "/CREATE_FAMILY_SETTINGS_FULFILLED";
export const CREATE_FAMILY_SETTINGS_REJECTED = NAME + "/CREATE_FAMILY_SETTINGS_REJECTED";

export const UPDATE_FAMILY_SETTINGS = NAME + "/UPDATE_FAMILY_SETTINGS";
export const UPDATE_FAMILY_SETTINGS_FULFILLED = NAME + "/UPDATE_FAMILY_SETTINGS_FULFILLED";
export const UPDATE_FAMILY_SETTINGS_REJECTED = NAME + "/UPDATE_FAMILY_SETTINGS_REJECTED";

export const DELETE_FAMILY_SETTINGS = NAME + "/DELETE_FAMILY_SETTINGS";
export const DELETE_FAMILY_SETTINGS_FULFILLED = NAME + "/DELETE_FAMILY_SETTINGS_FULFILLED";
export const DELETE_FAMILY_SETTINGS_REJECTED = NAME + "/DELETE_FAMILY_SETTINGS_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";

