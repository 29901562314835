import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Código de empleado",
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Código de familiar",
            field: "Código de familiar",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Apellido paterno",
            field: "Apellido paterno",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Apellido materno",
            field: "Apellido materno",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Primer nombre",
            field: "Primer nombre",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Segundo nombre",
            field: "Segundo nombre",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Apellidos y nombres",
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Tipo de familiar",
            field: "Tipo de familiar string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Sexo",
            field: "Sexo string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: 'Fecha de ingreso',
            field: "Fecha de ingreso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: 'Fecha de nacimiento',
            field: "Fecha de nacimiento",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: 'Fecha de casamiento',
            field: "Fecha de casamiento",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Asignación familiar",
            field: "Asignación familiar string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: 'Fecha de vencimiento de la asignación',
            field: "Fecha de vencimiento de la asignación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Retención judicial",
            field: "Retención judicial string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Incapacidad",
            field: "Incapacidad string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Escolaridad",
            field: "Escolaridad string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.familyGroup],
        config: {
            headerName: "Año del certificado escolar",
            field: "Año del certificado escolar",
            filter: "agSetColumnFilter",
        }
    },
]